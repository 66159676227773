import SpinnerContext from 'context/spinnerContext';
import {FormProvider, useForm} from 'hooks/custom-react-hook-form';
import {signIn} from 'next-auth/react';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {useContext} from 'react';
import yupSchema from './getSchema';

import {yupResolver} from '@hookform/resolvers/yup';

import {palette} from 'styles/mui/scss';

import {Box, Button, Grid, Typography} from 'components/basic-components';

import styles from './LoginForm.module.scss';
import {FormInputText} from "components/basic-components/Form/FormInputText";

const LoginForm = ({
  onLoginSuccess = () => {},
  onForgotPasswordClick,
}: {
  onLoginSuccess: Function;
  onForgotPasswordClick: () => void;
}) => {
  const {t} = useTranslation(['loginModal', 'password', 'forms', 'common']);
  const schema = yupSchema(t);

  const useFormMethods = useForm({
    name: 'LoginForm',
    defaultValues: {email: '', password: ''},
    resolver: yupResolver(schema),
  });
  const {handleSubmit, setError, clearErrors} = useFormMethods;
  const {addRequestWithGeneratedId} = useContext(SpinnerContext);

  const submitLogin = async ({email, password}) => {
    const {resolve} = addRequestWithGeneratedId();
    try {
      clearErrors(['email', 'password']);
      await signIn('credentials', {username: email, password: password, redirect: false}).then(
        ({ok}) => {
          if (ok) {
            onLoginSuccess();
          } else {
            setError('email', {message: t('pwOrUsernameWrong', {ns: 'password'}), type: 'custom'});
            setError('password', {message: t('pwOrUsernameWrong', {ns: 'password'}), type: 'custom'});
          }
          resolve();
        },
      );
      return;
    } catch (err) {
      resolve();
      setError('email', {message: t('pwOrUsernameWrong', {ns: 'password'}), type: 'custom'});
      setError('password', {message: t('pwOrUsernameWrong', {ns: 'password'}), type: 'custom'});
      return;
    }
  };
  const submitForm = handleSubmit((data: {email: string; password: string}) => {
    submitLogin(data);
  });
  return (
    <FormProvider {...useFormMethods}>
      <form data-role="login-form" onSubmit={submitForm}>
        <Grid container columnSpacing={8} rowSpacing={5}>
          <Grid xxs={12}>
            <FormInputText
              label={t('email', {ns: 'common'})}
              placeholder={t('email', {ns: 'common'})}
              variant="filled"
              sx={{
                width: '100%',
                height: '100%',
                '& .MuiFilledInput-root': {outline: `1px solid ${palette.colorMidGrey}`},
              }}
              name="email"
              key="email"
              autoComplete="email"
              validateOnBlur={true}
            />
          </Grid>
          <Grid xxs={12}>
            <FormInputText
              label={t('pw', {ns: 'password'})}
              placeholder={t('pw', {ns: 'password'})}
              variant="filled"
              sx={{
                width: '100%',
                height: '100%',
                '& .MuiFilledInput-root': {outline: `1px solid ${palette.colorMidGrey}`},
              }}
              name="password"
              key="password"
              type="password"
              autoComplete="password"
            />
          </Grid>
        </Grid>
        <Box className={styles.buttonWrapper}>
          <Button className={styles.button} type="submit">
            {t('login')}
          </Button>
        </Box>
      </form>

      <Typography
        component="span"
        role="button"
        tabIndex={0}
        onClick={onForgotPasswordClick}
        className={styles.forgotPassword}
      >
        {t('forgotPw?', {ns: 'password'})}
      </Typography>
    </FormProvider>
  );
};
export default LoginForm;
