import {NEXT_PUBLIC_BASE_URL} from 'config/index';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';

export interface ITrafficCookieData {
  traffic_source?: string;
  traffic_medium?: string;
  traffic_campaign?: string;
  last_update?: number;
}
const initialTraffic = {
  traffic_source: '',
  traffic_medium: '',
  traffic_campaign: '',
} as ITrafficCookieData;
// if the cookie was last updated in the last 60 minutes, returns true
const isLastUpdateWithin60Minutes = (trafficData: ITrafficCookieData): boolean => {
  if (trafficData.last_update !== undefined) {
    const currentTime = Math.floor(Date.now());
    const sixtyMinutesInMiliSeconds = 60 * 60 * 1000;

    return currentTime - trafficData.last_update < sixtyMinutesInMiliSeconds;
  }

  return false;
};
export const useTrafficSourceCookie = state => {
  const [cookies, setCookie, removeCookie] = useCookies(['traffic']);
  const {asPath} = useRouter();
  const [trafficData, setTrafficData] = useState<ITrafficCookieData>(
    cookies['traffic'] || initialTraffic,
  );
  const setTrafficDataWithDate = (data: ITrafficCookieData) => {
    const currentTimestamp = Math.floor(Date.now());
    setTrafficData({
      ...data,
      last_update: currentTimestamp,
    });
  };
  // if the user navigates to a new page, update the last_updated value with the current timestamp , if it was set in the last 60 minutes
  useEffect(() => {
    if (isLastUpdateWithin60Minutes(trafficData)) {
      setTrafficDataWithDate(trafficData);
      return;
    }
  }, [asPath]);
  useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search);
    const referer = document.referrer;
    // dont update TrafficData if it was updated in the last 60 minutes
    if (isLastUpdateWithin60Minutes(trafficData)) {
      return;
    }
    // make sure to also handle capitalize or characters in uppercase for params
    currentParams.forEach((value, key) => {
      currentParams.set(key.toLowerCase(), value);
    });

    const hasTrafficParams =
      currentParams.get('trafficsource') &&
      currentParams.get('trafficmedium') &&
      currentParams.get('trafficcampaign');
    const hasGoogleAds =
      referer &&
      !referer.includes(NEXT_PUBLIC_BASE_URL) &&
      /\b(google)\b/.test(referer) &&
      currentParams.get('gclid');
    const hasUtm =
      currentParams.get('utm_campaign') ||
      currentParams.get('utm_medium') ||
      currentParams.get('utm_source');
    const hasBingAds =
      referer &&
      !referer.includes(NEXT_PUBLIC_BASE_URL) &&
      /\b(bing)\b/.test(referer) &&
      currentParams.get('msclkid');
    const affiliatePattern =
      /\b(?:urlaubs-gutscheine\.de|discoup\.com|gutscheine\.focus\.de|gutscheine\.bild\.de|mydealz\.de|grabatt\.de|gutegutscheine\.de|travel-dealz\.de|sparwelt\.de|gutscheine\.computerbild\.de|rewardany\.com)\b/;
    const hasAffiliateAds =
      referer &&
      !referer.includes(NEXT_PUBLIC_BASE_URL) &&
      affiliatePattern.test(referer) &&
      currentParams.get('iclid');
    const seoPattern =
      /\b(?:google|bing|de\.search\.yahoo\.com|ecosia\.org|duckduckgo|searchwings\.org|yahoo|yandex\.ru)\b/;
    const hasSeoReferer =
      referer && !referer.includes(NEXT_PUBLIC_BASE_URL) && seoPattern.test(referer);

    // first check for specific url search params
    if (hasTrafficParams) {
      setTrafficDataWithDate({
        traffic_source: currentParams.get('trafficsource') || '',
        traffic_medium: currentParams.get('trafficmedium') || '',
        traffic_campaign: currentParams.get('trafficcampaign') || '',
      });
    }

    // check for google ads
    if (!hasTrafficParams && hasGoogleAds) {
      setTrafficDataWithDate({
        traffic_source: 'sea',
        traffic_medium: 'google_ads',
        traffic_campaign: '',
      });
    }

    // check for bing ads
    if (!hasTrafficParams && !hasGoogleAds && hasBingAds) {
      setTrafficDataWithDate({
        traffic_source: 'sea',
        traffic_medium: 'bing_ads',
        traffic_campaign: '',
      });
    }

    // check affiliate ads
    if (!hasTrafficParams && !hasGoogleAds && !hasBingAds && hasAffiliateAds) {
      setTrafficDataWithDate({
        traffic_source: 'affiliate',
        traffic_medium: 'belboon',
        traffic_campaign: '',
      });
    }

    // check predefined seo referer
    if (!hasTrafficParams && !hasGoogleAds && !hasBingAds && !hasAffiliateAds && hasSeoReferer) {
      setTrafficDataWithDate({
        traffic_source: 'seo',
        traffic_medium: '',
        traffic_campaign: '',
      });
    }
    if (hasUtm) {
      const utm_source = currentParams.get('utm_source');
      const utm_medium = currentParams.get('utm_medium');
      const utm_campaign = currentParams.get('utm_campaign');
      let res = {
        traffic_source: utm_source || '',
        traffic_medium: utm_medium || '',
        traffic_campaign: utm_campaign || '',
      };
      if (utm_source === 'google' && utm_medium === 'cpc') {
        res.traffic_source = 'sea';
        res.traffic_medium = 'google_ads';
      }
      if (utm_source === 'bing' && utm_medium === 'cpc') {
        res.traffic_source = 'sea';
        res.traffic_medium = 'bing_ads';
      }
      if (utm_source === 'belboon' && utm_medium === 'affiliate') {
        res.traffic_source = 'affiliate';
        res.traffic_medium = 'belboon';
      }
      setTrafficDataWithDate(res);
    }
    // check if other referer
    if (
      !hasTrafficParams &&
      !hasGoogleAds &&
      !hasBingAds &&
      !hasAffiliateAds &&
      !hasSeoReferer &&
      !hasUtm &&
      referer &&
      !referer.includes(NEXT_PUBLIC_BASE_URL)
    ) {
      setTrafficDataWithDate({
        traffic_source: 'others',
        traffic_medium: '',
        traffic_campaign: '',
      });
    }
  }, []);

  useEffect(() => {
    if (state.marketing) {
      setCookie('traffic', trafficData, {
        path: '/',
        sameSite: 'lax',
        maxAge: 60 * 60 * 24 * 30,
        domain: `.${NEXT_PUBLIC_BASE_URL.replace('local.', '')
          .replace('www.', '')
          .replace(/mr-\d*\./gi, '')
          .replace('https://', '')
          .replace('http://', '')}`,
      });
    }
  }, [state.marketing, trafficData]);
};
