import clsx from 'clsx';
import {Trans, useTranslation} from 'hooks/translations/useCustomTranslation';
import Image from 'next/image';
import {useRouter} from 'next/router';
import badIcon from 'public/assets/bad.png';
import goodIcon from 'public/assets/good.png';
import midIcon from 'public/assets/mid.png';
import React, {useContext, useEffect} from 'react';

import {Box, Button, Typography} from '@mui/material';

import {usePostApiV1StoreFeedback} from 'lib/api/backend';

import {getFeedbackDataLayer} from 'utils/tagManagerHelpers/gtmEventsParsing';

import {Grid, NextLink, TextField} from 'components/basic-components';
import {ConsentContext} from 'components/section-components/ConsentManager';

import {TFeedBackOptions} from './FeedbackDrawer';
import styles from './feedbackDialogue.module.scss';

export const FeedbackForm = ({
  setSelectedFeedback,
  setFeedback,
  selectedFeedback,
  setIsSent,
  feedback,
}: {
  setSelectedFeedback: React.Dispatch<React.SetStateAction<TFeedBackOptions>>;
  setFeedback: React.Dispatch<React.SetStateAction<string>>;
  feedback: string;
  selectedFeedback: TFeedBackOptions;
  setIsSent: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {t} = useTranslation('feedback');
  const {pushDataLayer} = useContext(ConsentContext);

  const {mutate, isError, isLoading, isSuccess, error} = usePostApiV1StoreFeedback();
  const {
    locale,
    query: {reservationId},
  } = useRouter();
  useEffect(() => {
    if (isSuccess) {
      pushDataLayer(getFeedbackDataLayer({efeedback: selectedFeedback}));
      setIsSent(true);
    }
  }, [isSuccess]);

  return (
    <Box
      sx={{
        width: {sm: '420px', xxs: '100vw'},
        maxWidth: '100vw',
        padding: '3%',
        height: 'calc(100% + 220px)',
        zIndex: -1,
      }}
    >
      <Typography component={'p'} variant="h1">
        {t('title')}
      </Typography>
      <Typography component={'p'} variant="h2" sx={{mt: '20px'}}>
        {t('subtitle')}
      </Typography>
      <Typography sx={{mt: '20px', lineHeight: '24px'}}>
        {t('description')}
        <br />
        <br />
        {t('feedback_info')}
      </Typography>

      <Typography sx={{fontWeight: 700, mt: '20px', mb: 3}}>{t('give_feedback')}</Typography>

      <Box sx={{display: 'flex', justifyContent: 'space-around', mt: 1, height: 85}}>
        <div className={styles.iconWrapper}>
          <Image
            src={goodIcon}
            alt="Good feedback"
            className={clsx({
              [styles.feedbackIcon]: true,
              [styles.active]: 'good' === selectedFeedback,
            })}
            onClick={() => setSelectedFeedback('good')}
          />
        </div>
        <div className={styles.iconWrapper}>
          <Image
            src={midIcon}
            alt="Neutral feedback"
            className={clsx({
              [styles.feedbackIcon]: true,
              [styles.active]: 'mid' === selectedFeedback,
            })}
            onClick={() => setSelectedFeedback('mid')}
          />
        </div>

        <div className={styles.iconWrapper}>
          <Image
            src={badIcon}
            alt="Bad feedback"
            className={clsx({
              [styles.feedbackIcon]: true,
              [styles.active]: 'bad' === selectedFeedback,
            })}
            onClick={() => setSelectedFeedback('bad')}
          />
        </div>
      </Box>
      <Grid container>
        <TextField
          label={t('message_label')}
          // variant="standard"
          fullWidth
          sx={{mt: 2, height: 'auto'}}
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          multiline
          rows={3}
        />

        <Typography variant="caption" sx={{mt: 1}}>
          <Trans
            t={t}
            i18nKey="dataProtection"
            components={[
              <NextLink
                key="link"
                target="_blank"
                href={locale === 'de' ? '/datenschutz/' : '/privacy-policy/'}
                style={{textDecoration: 'underline', fontWeight: 'bold', color: 'black'}}
              ></NextLink>,
            ]}
          />
        </Typography>
        <Grid xxsOffset={6} xxs={6}>
          <Button
            variant="inverted"
            color="primary"
            fullWidth
            disabled={!selectedFeedback || isLoading}
            sx={{mt: '20px', mb: '20px', alignSelf: 'flex-end'}}
            onClick={() => {
              mutate({
                data: {
                  mood: selectedFeedback,
                  message: feedback,
                  reservationId: (reservationId as string) || '',
                  viewpointInnerWidth: `${window.innerWidth}` || '',
                  viewpointInnerHeight: `${window.innerHeight}` || '',
                },
              });
            }}
          >
            <span style={{opacity: !selectedFeedback || isLoading ? 0.7 : 1}}>{t('submit')}</span>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeedbackForm;
