import * as yup from 'yup';

const emailRegExp = /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

const yupSchema = t => {
  const schema = yup.object({
    email: yup
      .string()
      .trim()
      .required(t('fieldShouldntBeEmpty', {ns: 'forms'}))
      .matches(emailRegExp, {message: t('invalidMail', {ns: 'forms'})}),
  });

  return schema;
};

export default yupSchema;
